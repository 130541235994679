import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import { styles } from "../utils"
import { graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import { AboutHeader, BannerCenter } from "../utils"

const AboutPage = ({ data: { hero, team } }) => {
  const src = getSrc(hero)

  return (
    <Layout>
      <Seo
        title="About"
        keywords={[
          `About Us`,
          `Bar & Kitchen in Kenilworth, Asheville, NC`,
          `Local inspired food and craft cocktails`,
        ]}
      />
      <AboutHeader img={src}>
        <BannerCenter
          headerProps={{ style: { textAlign: `left` } }}
          title="Locally made. Locally craved."
          titleProps={{
            style: {
              fontSize: `clamp(1.25rem, 3.5rem, 14vw)`,
            },
          }}
          subtitle="Come as You Are for Southern Fusion Fare & Craft Cocktails"
        >
          <AboutText>
            <p>
              Located in Kenilworth on the property of{" "}
              <RABLink href="https://www.residencesatbiltmore.com/">
                The Residences at Biltmore
              </RABLink>{" "}
              all-suite hotel, Azalea’s spacious outdoor lounge is perfect for a
              neighborly hang. Our modern, newly renovated dining space and
              fully stocked bar offer an alternative to the fast pace of
              downtown eateries.
            </p>
          </AboutText>
        </BannerCenter>
      </AboutHeader>
      {team.nodes.map(
        (
          { firstName: first, lastName: last, jobTitle, about, images },
          index,
        ) => {
          const image = getImage(images[0])
          const name = first === last ? first : `${first} ${last}`

          return (
            <BioSection key={index}>
              <Bio name={name}>
                <header>
                  <h3>{name}</h3>
                  <span className="title">{jobTitle}</span>
                  {renderRichText(about, {})}
                </header>
                <GatsbyImage
                  alt={`${name} - ${jobTitle} at Azalea Bar & Kitchen`}
                  className="teamPortrait"
                  image={image}
                />
              </Bio>
            </BioSection>
          )
        },
      )}
    </Layout>
  )
}

const RABLink = styled.a`
  color: #fff;
  text-decoration: underline ${styles.colors.secondaryColor};
`

const AboutText = styled.article`
  color: ${styles.colors.mainWhite};
  margin: 2.5rem auto auto auto;

  & > p {
    font-size: 1.25rem;
  }
`

const BioSection = styled.section`
  background: ${styles.colors.primaryColor};
  height: fit-content;
  width: 100%;
`

const Bio = styled.article`
  background-color: inherit;
  color: ${styles.colors.mainWhite};
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  gap: 2rem;
  isolation: isolate;
  margin: auto;
  max-width: min(90vw, 50ch);
  overflow: hidden;
  position: relative;
  width: 100%;

  &:first-child {
    padding-top: 4rem;
  }

  &:last-child {
    padding-bottom: 4rem;
  }

  header {
    h3 {
      color: ${styles.colors.secondaryColor};
      font: ${styles.fontBody({ weight: 700, size: `2rem` })};
      text-transform: none;
      transition: 300ms ease-in-out;
    }

    .title {
      font: ${styles.fontBody({ size: `1rem`, weight: 700 })};
      letter-spacing: 2px;
      text-transform: uppercase;
      text-wrap: balance;
    }

    p {
      margin-top: 1rem;
    }
  }

  .teamPortrait {
    position: relative;
    width: fit-content;
  }

  @media (min-width: 768px) {
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    gap: 0;
    max-width: min(95vw, 768px);

    &::after {
      inset: 60% auto auto 40%;
    }

    header {
      max-width: 45ch;
    }
  }
`

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "abk-bar-1-h.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          formats: [AVIF, WEBP]
          placeholder: BLURRED
          transformOptions: { fit: COVER }
          width: 2000
        )
      }
    }
    team: allContentfulTeamMember(
      filter: {
        organizations: { elemMatch: { name: { eq: "Azalea Bar & Kitchen" } } }
      }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        firstName
        lastName
        jobTitle
        about {
          raw
        }
        images {
          gatsbyImageData(
            quality: 75
            formats: WEBP
            height: 375
            placeholder: BLURRED
          )
        }
      }
    }
  }
`

export default AboutPage
